import React, { useState } from "react";

const Dashboard= () => {
  const [loading, setLoading] = useState(true);

  // Function to hide loading symbol once iframe content is loaded
  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}

      <iframe
        src={process.env.REACT_APP_DASHBOARD_APP_ENDPOINT}
        title="Static Page"
        style={{ width: "100%", height: "100vh", border: "none", display: loading ? "none" : "block" }}
        onLoad={handleLoad} // Triggered when iframe content is loaded
      />
    </>
  );
};

export default Dashboard;
