import React, { useState } from "react"
import { FaUser } from "react-icons/fa"
import { HiUserGroup } from "react-icons/hi2"
import {
  Routes,
  Route,
  Link,
  useLocation,
  useOutletContext,
} from "react-router-dom"

import "./Settings.css"
import AccessControls from "./AccessControls/AccessControls"
import Accounts from "./Accounts/Accounts"
import Profile from "./Profile/Profile"
import Dashboard from "./sync_dashboard/sync_dashboard"
import UserManagement from "./UserManagement/UserManagement"
import FullScreen from "../../shared/FullScreen/FullScreen"

const USER_SETTINGS = [
  {
    path: "profile",
    title: "Profile",
    component: <Profile />,
    icon: <FaUser />,
  },
  // {
  //   path: "dashboard",
  //   title: "Sync Dashboard",
  //   component: <Dashboard/>,
  //   icon: <FaUser />,
  // },
  // {
  //   path: 'access-controls',
  //   title: 'Access Controls',
  //   component: <AccessControls />,
  // },
  {
    path: "user-management",
    title: "User Management",
    component: <UserManagement />,
    icon: <HiUserGroup />,
  },
  // { path: 'accounts', title: 'Accounts', component: <Accounts /> },
  // {
  //   path: 'login-history',
  //   title: 'Login History',
  //   component: <div>Login History Content</div>,
  // },
]
console.log(process.env.REACT_APP_SHOW_DASHBOARD)
if (process.env.REACT_APP_SHOW_DASHBOARD_FLAG == "true") {
  USER_SETTINGS.push({
    path: "dashboard",
    title: "Sync Dashboard",
    component: <Dashboard />,
    icon: <FaUser />,
  });
}
// const SYSTEM_SETTINGS = [

// {
//   path: 'business-details',
//   title: 'Business Details',
//   component: <div>Business Details Content</div>,
// },
// {
//   path: 'billing-purchases',
//   title: 'Billing & Purchases',
//   component: <div>Billing & purchases Content</div>,
// },
// {
//   path: 'actuals-fields',
//   title: 'Actuals Fields',
//   component: <div>Actuals Fields Content</div>,
// },
// { path: 'usage', title: 'Usage', component: <div>Usage Content</div> },
// {
//   path: 'approval-matrix',
//   title: 'Approval Matrix',
//   component: <div>Approval Matrix Content</div>,
// },
// {
//   path: 'system-controls',
//   title: 'System Controls',
//   component: <div>System Controls Content</div>,
// },
// ]
const RouteWrapper = ({ component, maxPermission }) => {
  return React.cloneElement(component, { maxPermission })
}

const renderLinks = (settings, basePath = "", currentPath) => (
  <ul className="mt-5">
    {settings.map(({ path, title, icon }) => (
      <li key={path} className="hover:bg-gray-100 hover:rounded-md mb-2">
        <Link
          to={`${basePath}/${path}`}
          className={`block py-2 text-sm px-2 flex ${
            currentPath.includes(`${basePath}/${path}`)
              ? " font-semibold bg-blue-400 text-white rounded-md  "
              : " "
          }`}
        >
          <div className="mr-2 flex justify-center items-center">{icon}</div>
          <div>{title}</div>
        </Link>
      </li>
    ))}
  </ul>
)

const renderRoutes = (settings, basePath, maxPermission) =>
  settings.map(({ path, component }) => (
    <Route
      key={path}
      path={`${basePath}/${path}`}
      element={
        <RouteWrapper component={component} maxPermission={maxPermission} />
      }
    />
  ))

const Settings = () => {
  const location = useLocation()
  const [hideHomeHeader, maxPermission] = useOutletContext()

  return (
    <div className={`flex max-w-full mx-auto p-4 relative`}>
      <div className="w-1/5 bg-white p-4 rounded shadow-md left-section">
        <div className="mb-4 ">
          <p className="text-base font-bold mb-2 underline underline-offset-8  px-2 flex  items-center">
            User Settings
          </p>
          {renderLinks(USER_SETTINGS, "user", location.pathname)}
        </div>
        {/* <div className="mb-4">
          <p className="text-base font-bold mb-2 underline underline-offset-8 px-2">
            System Settings
          </p>
          {renderLinks(SYSTEM_SETTINGS, 'system', location.pathname)}
        </div> */}
      </div>
      <div
        className="flex-1 bg-gray-50 p-4 rounded shadow-md right-section"
        style={hideHomeHeader ? { height: "93vh" } : { height: "76vh" }}
      >
        <Routes>
          {renderRoutes(USER_SETTINGS, "user", maxPermission)}
          {/* {renderRoutes(SYSTEM_SETTINGS, 'system', maxPermission)} */}
        </Routes>
      </div>
    </div>
  )
}

export default Settings
